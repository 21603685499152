<template>
  <div v-if="employer" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="employer">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employer.name" type="text" :placeholder="$t('name')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('street')"
            label-for="street"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employer.street" type="text" :placeholder="$t('street')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('zip')"
            label-for="zip"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employer.zip" type="text" :placeholder="$t('zip')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('city')"
            label-for="city"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employer.city" type="text" :placeholder="$t('city')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('description')"
            label-for="description"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-textarea
              id="description"
              v-model="employer.description"
              placeholder="..."
              rows="4"
              max-rows="20"
            ></b-form-textarea>
          </b-form-group>

          <legend v-if="isAdmin">
            <strong>{{ $t('client') }}</strong>
          </legend>

          <b-form-group
            v-if="isAdmin"
            horizontal
            :label="$t('client')"
            label-for="client"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('client') + ' ' + $t('select')"
              v-model="employer.client"
              :options="getClients"
              :multiple="false"
              :show-labels="true"
              :allow-empty="true"
              track-by="id"
              label="name"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Ansprechpartner</strong>
            <div class="button-right">
              <button class="btn btn-sm btn-success right" @click.prevent="onAddContact">
                <i class="fa fa-plus" />
              </button>
            </div>
          </legend>

          <div class="employerContacts" v-for="(contact, index) in employer.contacts" :key="index">
            <b-form-group
              horizontal
              :label="$t('firstname')"
              label-for="firstname"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input
                v-model="contact.firstname"
                type="text"
                :placeholder="$t('firstname')"
              />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('lastname')"
              label-for="lastname"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.lastname" type="text" :placeholder="$t('lastname')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('position')"
              label-for="position"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.position" type="text" :placeholder="$t('position')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('phone')"
              label-for="phone"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.phone" type="text" :placeholder="$t('phone')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('mobile')"
              label-for="mobile"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.mobile" type="text" :placeholder="$t('mobile')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('email')"
              label-for="email"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.email" type="text" :placeholder="$t('email')" />
            </b-form-group>
            <i class="fa fa-trash-o trash-contact" @click="onTrashContact(index)" />
            <hr />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="employer"
            modelEditRoute="EmployerEditPage"
            modelListRoute="EmployersPage"
            modelRouteParamName="employerNumber"
            :updateCallback="updateEmployer"
            :createCallback="createEmployer"
            :deleteCallback="deleteEmployer"
            :fetchCallback="fetchEmployers"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'EmployerEditPage',
  components: {
    Widget,
    Breadcrumbs,
    Multiselect,
    ButtonBar,
  },
  props: {
    employerNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['getEmployer', 'getEmployerByNumber', 'getClients']),
    buttons() {
      return {
        showDeleteButton: this.employer && this.employer.number !== '_new' ? true : false,
      };
    },
    employer() {
      return this.getEmployerByNumber(this.number);
    },
    isNew() {
      return this.employer.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('employer'), route: { name: 'EmployersPage' } },
        { name: this.employer.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initEmployer',
      'fetchEmployers',
      'fetchEmployerByNumber',
      'updateEmployer',
      'createEmployer',
      'deleteEmployer',
      'addEmptyEmployerContact',
      'removeEmployerContact',
      'fetchClients',
    ]),
    onAddContact() {
      this.addEmptyEmployerContact(this.employer);
    },
    onTrashContact(index) {
      this.removeEmployerContact({
        updatedEmployer: this.employer,
        index: index,
      });
    },
  },
  async mounted() {
    if (!this.employer && this.employerNumber) {
      this.number = this.employerNumber;
      await this.fetchEmployerByNumber(this.number);
    } else {
      this.initEmployer();
      this.number = '_new';
    }

    if (this.isAdmin) {
      await this.fetchClients();
    }
    if (this.isClient) {
      this.employer.client = this.getCurrentUser.client;
    }
  },
};
</script>

<style scoped lang="scss">
.employerContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}
</style>
